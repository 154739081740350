/* eslint-disable @nx/enforce-module-boundaries */
import { Button, HStack, VStack } from '@mybridge/ui';
import { forwardRef, useContext } from 'react';
import { SearchFilterFromCompany } from 'v4/components/filters/company-name';
import { SearchFilterDatePosted } from 'v4/components/filters/date-posted';
import { SearchFilterExperienceLevel } from 'v4/components/filters/experience-level';
import { SearchFilterJobPremise } from 'v4/components/filters/job-premise';
import { SearchFilterJobType } from 'v4/components/filters/job-type';
import { SearchContext } from '../../context';
import { css } from '@chakra-ui/react';
import { DeleteIcon } from '@mybridge/icons';
export const SearchJobsFilters = forwardRef(({ ...props }, ref) => {
  const { jobsFilters, setJobsFilters, resetFilters, setResetFilters } = useContext(SearchContext);
  const { date_posted, experience_level, company, job_type, remote } =
    jobsFilters ?? {};

  const onFilterChange = (filter) => {
    setJobsFilters({ ...(jobsFilters ?? {}), ...(filter ?? {}) });
    // setResetFilters(true)
  };

  
  return (
    <>
      <VStack align='stretch' p={3} gap={3} justifyContent="flex-start">
        <SearchFilterDatePosted
          defaultValue={date_posted}
          onChange={onFilterChange}
        />
        <SearchFilterExperienceLevel
          defaultValue={experience_level}
          onChange={onFilterChange}
        />
        <SearchFilterFromCompany
          name="company"
          title="Company"
          defaultValue={company}
          onChange={onFilterChange}
        />
        <SearchFilterJobType
          defaultValue={job_type}
          onChange={onFilterChange}
        />
        <SearchFilterJobPremise
          defaultValue={remote}
          onChange={onFilterChange}
        />
        
      </VStack>
      {/* {jobsFilters &&
        Object.keys(jobsFilters ?? {})?.length ? (
          <Button
          variant="secondary"
          justifyContent="flex-start"
          color="#5B5B5B"
          leftIcon={<DeleteIcon />}
          ms="26px"
            onClick={() => setJobsFilters()}
          >
            Clear Filters
          </Button>
        ) : (
          ''
        )} */}
    </>
  );
});
