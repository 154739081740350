/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  Card,
  Container,
  HStack,
  Heading,
  Scrollable,
  Stack,
} from '@mybridge/ui';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MainCopyrightsText } from 'v4/components/copyrights-text';
import { NewUserPanel } from 'v4/snippets/dashboard/public-view/new-user-panel';
import { SearchContext } from 'v4/snippets/search/context';
import { SearchSidebar } from 'v4/snippets/search/sidebar';
import { DashboardCalendar } from 'v4/snippets/dashboard/calendar';
import CalendarSidebar from 'v4/snippets/calendar/calendar-sidebar';
import { isMobile } from 'react-device-detect';

export const SearchLayout = ({ activeTab, children }) => {
  const {
    query,
    setActiveTab,
    companyPagesFilters,
    peopleFilters,
    postsFilters,
    teamsFilters,
    articlesFilters,
    eventsFilters,
    jobsFilters,
    setPeopleFilters, setPostsFilters, setTeamsFilters, setArticlesFilters, setEventsFilters, setJobsFilters, setCompanyPagesFilters, resetFilters, setResetFilters
  } = useContext(SearchContext);

  const { loggedIn } = useSelector((s) => s.user);

  useEffect(() => {
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [activeTab]);

  // useEffect(() => {
  //   if (postsFilters?.date_posted || postsFilters?.posted_by || postsFilters?.sort_by || postsFilters?.from_member || postsFilters?.from_company != null) {
  //     setResetFilters(true)
  //   } else if (peopleFilters?.locations || peopleFilters?.current_company || peopleFilters?.connections != null) {
  //     setResetFilters(true)
  //   } else {
  //     setResetFilters(false)
  //   }
  // }, [postsFilters, peopleFilters])

  // useEffect(() => {
  //   if (peopleFilters?.locations || peopleFilters?.current_company || peopleFilters?.connections != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [peopleFilters])

  // useEffect(() => {
  //   if (companyPagesFilters?.location || companyPagesFilters?.industry || companyPagesFilters?.company_size != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [companyPagesFilters])

  // useEffect(() => {
  //   if (teamsFilters?.location || teamsFilters?.industry != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [teamsFilters])

  // useEffect(() => {
  //   if (articlesFilters?.posted_by || articlesFilters?.date_posted || articlesFilters?.sort_by || articlesFilters?.from_member || articlesFilters?.from_company != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [articlesFilters])

  // useEffect(() => {
  //   if (eventsFilters?.posted_by || eventsFilters?.date_posted || eventsFilters?.sort_by || eventsFilters?.from_member || eventsFilters?.from_company != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [eventsFilters])

  // useEffect(() => {
  //   if (jobsFilters?.date_posted || jobsFilters?.experience_level || jobsFilters?.company || jobsFilters?.job_type || jobsFilters?.remote != null) {
  //     setResetFilters(true)
  //   }else {
  //     setResetFilters(false)
  //   }
  // }, [jobsFilters])

  const handleResetFilters = () => {
    setResetFilters(false); setPeopleFilters(); setPostsFilters(); setTeamsFilters(); setArticlesFilters(); setEventsFilters(); setJobsFilters(); setCompanyPagesFilters();
  }

  return (
    <>
      <HStack
        alignItems="flex-start"
        spacing={0}
        gap={4}
        flexDir={['column', 'column', 'row']}
        // border="1px solid red"
        // bg="white"
      >
        {!isMobile ? (
          <Box
            position={['initial', 'initial', 'sticky']}
            top="64px"
            w={['full', 'full', '360px']}
            flexShrink={0}
            bg="white"
            boxShadow="2px 0px 6px 0px #00000033"
          >
            <Scrollable>
              <Stack h="calc(100vh - 64px)">
                <Box flex={1}>
                  <Card p={[0, 0, 4]} overflowX="auto" boxShadow="none">
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      {query?.query?.length ? (
                        <Heading
                          display={['none', 'none', 'block']}
                          size="sm"
                          color="brandPrimary.500"
                          fontWeight="400"
                        >
                          Search Results
                        </Heading>
                      ) : (
                        ''
                      )}
                      {(companyPagesFilters ||
                        peopleFilters ||
                        postsFilters ||
                        teamsFilters ||
                        articlesFilters ||
                        eventsFilters ||
                        jobsFilters) && resetFilters &&
                      Object.keys(
                        companyPagesFilters ||
                          peopleFilters ||
                          postsFilters ||
                          teamsFilters ||
                          articlesFilters ||
                          eventsFilters ||
                          jobsFilters
                      )?.length ? (
                        <Button
                          size="sm"
                          variant="transparent"
                          _hover={{ bg: '#E4E6EB' }}
                          onClick={() => handleResetFilters()}
                        >
                          Reset
                        </Button>
                      ) : (
                        ''
                      )}
                    </HStack>
                    <Stack
                      spacing={0}
                      gap={2}
                      flexDir={['row', 'row', 'column']}
                      maxW="50vh"
                    >
                      <SearchSidebar />
                    </Stack>
                  </Card>
                </Box>
                <Box display={['none', 'none', 'block']} mb={3} pl={6}>
                  <MainCopyrightsText alignment="flex-start" />
                </Box>
              </Stack>
            </Scrollable>
          </Box>
        ) : (
          <Box
            position={['initial', 'initial', 'sticky']}
            top="108px"
            w={['full', 'full', '360px']}
            flexShrink={0}
            bg="white"
            boxShadow="2px 0px 6px 0px #00000033"
          >
            <SearchSidebar />
          </Box>
        )}

        <Box w={['100%', 'full', '']} flex={1} maxW="100%" px={[2, 0]} py={3}>
          <Container maxW="700px" px={0}>
            {children}
          </Container>
        </Box>

        {/* {!loggedIn && !isMobile ? (
          <Scrollable
            display={loggedIn ? 'none' : 'block'}
            position={['initial', 'initial', 'sticky']}
            top={20}
            w={['full', 'full', '25%']}
            flexShrink={0}
            as={Card}
          >
            <NewUserPanel />
          </Scrollable>
        ) : (
          <></>
        )} */}
        {/* {loggedIn && !isMobile ? (
          <Scrollable
            display={loggedIn ? 'block' : 'none'}
            position={['initial', 'initial', 'sticky']}
            top={20}
            w={['full', 'full', '25%']}
            flexShrink={0}
          >
            <Stack>
              <DashboardCalendar />
              <CalendarSidebar />
            </Stack>
          </Scrollable>
        ) : (
          <></>
        )} */}
      </HStack>
    </>
  );
};
