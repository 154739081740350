/* eslint-disable @nx/enforce-module-boundaries */
import { Box, Card } from '@mybridge/ui';
import React, {useState} from 'react';
import Calendar from 'react-calendar';
import { useRouter } from 'next/router';
export const DashboardCalendar = () => {
  const router = useRouter();
  const [value, onChange] = useState(new Date());
  return (
    <Box pt={2}>
      <Calendar prev2Label={null} next2Label={null} onChange={(e) => {
            localStorage.setItem('dateSelectedFromDashboard', e.toISOString());
            router.push('/profile/calendar')
          }}
          value={value} />
    </Box>
  );
};
