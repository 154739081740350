/* eslint-disable @nx/enforce-module-boundaries */
import { Button, HStack, VStack } from '@mybridge/ui';
import { forwardRef, useContext } from 'react';
import { SearchFilterFromCompany } from 'v4/components/filters/company-name';
import { SearchFilterDatePosted } from 'v4/components/filters/date-posted';
import { SearchFilterFromMember } from 'v4/components/filters/from-member';
import { SearchFilterPostedBy } from 'v4/components/filters/posted-by';
import { SearchFilterSortBy } from 'v4/components/filters/sort-by';
import { SearchContext } from '../../context';
import _ from 'lodash';
import { css } from '@chakra-ui/react';
import { DeleteIcon } from '@mybridge/icons';
export const SearchEventsFilters = forwardRef(({ ...props }, ref) => {
  const { eventsFilters, setEventsFilters, loggedIn, eventsSearchListing, resetFilters, setResetFilters  } = useContext(SearchContext);

  const { posted_by, date_posted, sort_by, from_member, from_company } =
  eventsFilters ?? {};

  const onFilterChange = (filter) => {
    setEventsFilters({ ...(eventsFilters ?? {}), ...(filter ?? {}) });
    // refetchPostsSearch?.({ stale: true });
    // setResetFilters(true)
  };


  const fromMemberOptions = _.map(eventsSearchListing, 'author');
  const fromCompanyOptions = _.map(eventsSearchListing, 'company');


  return (
    <>
      <VStack align='stretch' p={3} gap={3} justifyContent="flex-start">
        {loggedIn && (
          <SearchFilterPostedBy
            defaultValue={posted_by}
            onChange={onFilterChange}
          />
        )}

        <SearchFilterDatePosted
          defaultValue={date_posted}
          onChange={onFilterChange}
        />
        <SearchFilterSortBy defaultValue={sort_by} onChange={onFilterChange} />
        <SearchFilterFromMember
          defaultValue={from_member}
          onChange={onFilterChange}
          defaultArray={fromMemberOptions}
        />
        {fromCompanyOptions?.length > 0 && <SearchFilterFromCompany
          defaultValue={from_company}
          onChange={onFilterChange}
          defaultArray={fromCompanyOptions}
        />}
        
        

        {/* <SearchFilterFromMember
          name="mentioning_member"
          title="Mentioning member"
          defaultValue={from_member}
          onChange={onFilterChange}
        /> */}
      </VStack>
      {/* {eventsFilters && Object.keys(eventsFilters)?.length ? (
          <Button variant="secondary"
          justifyContent="flex-start"
          color="#5B5B5B"
          leftIcon={<DeleteIcon />}
          ms="26px" onClick={() => setEventsFilters()}>
            Clear Filters
          </Button>
        ) : (
          ''
        )} */}
    </>
  );
});
